var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-12 mt-8"},[_c('div',{staticClass:"intro-y flex items-center h-10"},[_c('h2',{staticClass:"sm:text-2xl text-xl font-medium truncate mr-5"},[_vm._v("Operators")]),_c('div',{staticClass:"flex items-center ml-auto mt-0"},[_c('button',{staticClass:"button text-white bg-theme-1 shadow-md mr-2 text-xs sm:text-sm cursor-pointer",on:{"click":function($event){return _vm.$router.push({ path: '/asset-tracker/new-operator' })}}},[_vm._v(" + New Operator ")]),_c('button',{staticClass:"flex button text-white bg-theme-10 shadow-md mr-2 text-xs sm:text-sm cursor-pointer",on:{"click":_vm.onGenerateMISReport}},[_c('DownloadIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" MIS Report ")],1),_c('button',{staticClass:"flex button text-white bg-theme-9 shadow-md mr-2 text-xs sm:text-sm cursor-pointer",on:{"click":_vm.onGenerateReport}},[_c('DownloadIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Excel Report ")],1)])]),(_vm.isLoading)?_c('Loader'):_vm._e(),_c('Modal',{attrs:{"showModal":_vm.showDeleteModal,"onHideModal":_vm.onHideDeleteModal,"onSubmitModal":_vm.onDeleteAsset,"title":'Delete Asset?',"description":'Are you sure you want to delete your operator? All of your data will be permanently removed. This action cannot be undone.',"btnText":'Delete'}}),(!_vm.isLoading && _vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.assetsList.slice(
            _vm.assetsStartIndex,
            _vm.assetsEndIndex
          )),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/operator-detail/" + (asset.id)),
              })}}},[_c('span',{staticClass:"font-medium whitespace-no-wrap"},[_vm._v(_vm._s(asset.name))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/operator-detail/" + (asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.pin)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/operator-detail/" + (asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.asset ? asset.asset.brand : "- -")+" "+_vm._s(asset.asset ? asset.asset.model : "- -")+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/operator-detail/" + (asset.id)),
              })}}},[_c('span',{class:{
                'text-theme-9': asset.attendenace_today,
                'text-theme-6': !asset.attendenace_today,
              }},[_vm._v(_vm._s(asset.attendenace_today ? "Present" : "Absent"))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/operator-detail/" + (asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.present || " -- ")+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                path: ("/asset-tracker/operator-detail/" + (asset.id)),
              })}}},[_vm._v(" "+_vm._s(asset.absent || " -- ")+" ")]),_c('td',{staticClass:"table-report__action w-56"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"flex items-center justify-center text-gray-500 cursor-pointer mr-4",on:{"click":function($event){return _vm.$router.push({
                    path: ("/asset-tracker/edit-operator/" + (asset.id)),
                  })}}},[_c('EditIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Update ")],1),(!_vm.$store.state.isUserIsManager)?_c('div',{staticClass:"flex items-center justify-center text-theme-6 cursor-pointer",on:{"click":function($event){return _vm.onShowDeleteModal(asset.id)}}},[_c('TrashIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Delete ")],1):_vm._e()])])])}),0)])]):_vm._e(),(!_vm.isLoading && _vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3"},[_c('ul',{staticClass:"pagination"},[_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onPrevPagination}},[_c('ChevronLeftIcon',{staticClass:"w-4 h-4",class:{
              'text-gray-700 cursor-not-allowed': _vm.assetsCurrentPage == 1,
            }})],1)]),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.assetsStartIndex + 1)+"-"+_vm._s(_vm.assetsEndIndex))])]),_vm._m(1),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.assetsLength))])]),_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onNextPagination}},[_c('ChevronRightIcon',{staticClass:"w-4 h-4",class:{
              'text-gray-700 cursor-not-allowed':
                _vm.assetsCurrentPage == _vm.assetsTotalPages,
            }})],1)])])]):_vm._e(),(!_vm.isLoading && _vm.showNoData)?_c('NoData',{attrs:{"onReloadData":_vm.onReloadData,"type":_vm.noDataType}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("NAME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("PIN")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ASSET")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TODAY'S ATTENDANCE")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("NO OF DAYS PRESENT")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("NO OF DAYS ABSENT")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ACTION")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"pagination__link"},[_vm._v("of")])])}]

export { render, staticRenderFns }
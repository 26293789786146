















































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  EditIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon
} from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import Modal from "../components/ui/Modal.vue";
import NoData from "../components/ui/No-Data.vue";

import XLSX from "xlsx";

@Component({
  components: {
    EditIcon,
    TrashIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Loader,
    Modal,
    NoData,
    DownloadIcon
  },
})
export default class Operator extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  assetsList = [];
  isLoading = true;
  showDeleteModal = false;
  showNoData = false;
  noDataType = "";

  mounted() {
    setTimeout(() => {
      this.fetchAssetsList();
    }, 0);
  }

  onGenerateReport() {
    if (this.assetsList.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.assetsList.map((x: any) => ({
          asset: x.asset ? x.asset.brand + " " + x.asset.model : "- -",
          name: x.name,
          pin: x.pin,
          active: x.active,
          created_on: x.created_on,
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "operator.xlsx");
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No operator found",
        type: "Danger",
      });
    }
  }

   async onGenerateMISReport() {
    await fetch(`${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator/pdf`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "operator-report-" +
          new Date(Date.now()).toLocaleDateString().replaceAll("/", "-") +
          ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  onReloadData() {
    this.showNoData = false;
    this.isLoading = true;
    setTimeout(() => {
      this.fetchAssetsList();
    }, 0);
  }

  async fetchAssetsList() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.assetsList = await response.json();
        this.assetsLength = this.assetsList.length;
        this.initPaginations(this.assetsLength);

        if (this.assetsList.length === 0) {
          this.noDataType = "Empty";
          this.showNoData = true;
        }
      } else {
        this.noDataType = "Error";
        this.showNoData = true;
      }
    } catch (e) {
      this.noDataType = "Error";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  initPaginations(length: number) {
    this.assetsEndIndex = length > 5 ? 5 : length;
    this.assetsTotalPages =
      this.assetsList.length % 5 === 0
        ? Math.floor(this.assetsList.length / 5)
        : Math.floor(this.assetsList.length / 5 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.assetsList.length > this.assetsStartIndex + 5
        ? this.assetsStartIndex + 5
        : this.assetsList.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 5 <= 0 ? 0 : this.assetsStartIndex - 5;
  }

  onShowDeleteModal(id: string) {
    this.showDeleteModal = true;
    this.idToBeDeleted = id;
  }

  idToBeDeleted = "";
  async onDeleteAsset() {
    if (this.idToBeDeleted !== "") {
      try {
        const response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator/${this.idToBeDeleted}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.status === 200) {
          this.$store.commit("showToastMessage", {
            boldText: "Successfully!",
            text: "Operator removed",
            type: "Success",
          });
          this.onReloadData();
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Failed!",
            text: "Something went wrong",
            type: "Danger",
          });
        }
      } catch (e) {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: e.message,
          type: "Danger",
        });
      } finally {
        this.onHideDeleteModal();
      }
    } else {
      this.onHideDeleteModal();
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "Invalid id selected",
        type: "Danger",
      });
    }
  }

  onHideDeleteModal() {
    this.showDeleteModal = false;
  }
}
